const modulesFiles = require.context('./', false, /\.json$/);
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
    const moduleName = (modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')).replace(/-([a-z])/g, g => g[1].toUpperCase());
    if (!moduleName.includes('translation')) {
        modules[moduleName] = modulesFiles(modulePath);
    }
    return modules;
}, {});

module.exports = modules
