import styled from 'styled-components'

const AgeGateStyle = styled.div`
	--descriptionMarginTop: var(--sp4x);
	--contentWrapPadding: 0 var(--sp17x);
	--ageGateLogoTop: var(--sp2x);
	--ageGateLogoPaddingTop: var(--sp8x);
	--buttonsMBot: var(--sp8x);
	--buttonsDistance: var(--sp3x);

	--ovalWidthSize: 110vw;
	--ovalHeightSize: 45vh;
	--ovalPositionSize: -20%;

	position: fixed;
	inset: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: 100;
	background-color: var(--white);

	.age-gate-relative-wrapper {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.age-gate-logo {
			position: fixed;
			width: var(--logoWidth);
			top: var(--ageGateLogoTop);
			left: 50%;
			transform: translateX(-50%);
			z-index: 1000;

			.image-cont {
				padding-top: var(--logoHeight);
			}
		}

		.content-wrap {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: var(--contentWrapPadding);
			text-align: center;
			margin-top: calc(var(--buttonsMBot) * 2);

			.description {
				margin-top: var(--descriptionMarginTop);
			}
		}

		.buttons-wrap {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: var(--buttonsMBot);
			z-index: 1;

			.first-button {
				margin-right: var(--buttonsDistance);
			}
		}

		.curve-wrap {
			position: absolute;
			width: var(--ovalWidthSize);
			height: var(--ovalHeightSize);
			left: 50%;
			transform: translate3d(-50%, 0, 0);
			border-radius: 50%;
			background-color: var(--primaryColor1);

			&.top {
				top: var(--ovalPositionSize);
			}

			&.bottom {
				bottom: var(--ovalPositionSize);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--descriptionMarginTop: var(--sp3x);
		--contentWrapPadding: 0 var(--sp13x);
		--ageGateLogoTop: var(--sp2x);
		--ageGateLogoPaddingTop: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--descriptionMarginTop: var(--sp3x);
		--contentWrapPadding: 0 var(--sp10x);
		--ageGateLogoTop: var(--sp1x);
		--ageGateLogoPaddingTop: var(--sp6x);
		--buttonsMBot: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--descriptionMarginTop: var(--sp2x);
		--contentWrapPadding: 0 var(--sp8x);
		--ageGateLogoTop: var(--sp1x);
		--ageGateLogoPaddingTop: var(--sp6x);
		--buttonsMBot: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--descriptionMarginTop: var(--sp3x);
		--contentWrapPadding: 0 var(--sp7x);
		--ageGateLogoTop: var(--sp1x);
		--ageGateLogoPaddingTop: var(--sp6x);
		--buttonsMBot: var(--sp5x);
		--buttonsDistance: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--descriptionMarginTop: var(--sp2x);
		--contentWrapPadding: 0 var(--sp6x);
		--ageGateLogoTop: var(--sp1-5x);
		--ageGateLogoPaddingTop: var(--sp5x);
		--buttonsMBot: var(--sp6x);
		--buttonsDistance: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--descriptionMarginTop: var(--sp2x);
		--contentWrapPadding: 0 var(--sp13x);
		--ageGateLogoTop: var(--sp1-5x);
		--ageGateLogoPaddingTop: var(--sp5x);
		--buttonsMBot: var(--sp5x);
		--buttonsDistance: var(--sp2x);

		--ovalWidthSize: 135vw;
		--ovalHeightSize: 50vh;
		--ovalPositionSize: -25%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--descriptionMarginTop: var(--sp2x);
		--contentWrapPadding: 0 var(--sp3x);
		--ageGateLogoTop: var(--sp1-5x);
		--ageGateLogoPaddingTop: var(--sp4x);
		--buttonsMBot: var(--sp4x);
		--buttonsDistance: var(--sp2x);

		--ovalWidthSize: 160vw;
		--ovalHeightSize: 50vh;
		--ovalPositionSize: -25%;

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.MobileSizeSMin}) {
			--ovalPositionSize: -33%;
		}
	}
`

export default AgeGateStyle
