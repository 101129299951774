import { memo as Memo, useCallback } from 'react'
import { useRouter } from 'next/router'

//* HOC
import withUIContext from 'context/consumerHOC/UIConsumer'

//* Component
import Image from 'components/common/Image'

//* Style
import LogoStyle from './style'

const Logo = Memo(({ closeBurgerMenu, className }) => {
	//! Router
	const router = useRouter()

	const handleLogoClick = useCallback(() => {
		closeBurgerMenu()

		if (router.pathname !== '/') {
			router.push('/')
		} else {
			window.scrollTo({ top: 0, behavior: 'smooth' })
		}
	}, [router.pathname])

	return (
		<LogoStyle
			onClick={handleLogoClick}
			className={`cursor-pointer ${className || ''}`}
		>
			<Image
				src={`/images/logo/logo.svg`}
				alt={`Logo`}
			/>
		</LogoStyle>
	)
})

export default withUIContext(Logo, ['closeBurgerMenu'])
