import styled from 'styled-components'

const LogoStyle = styled.div`
	.image-cont {
		img {
			object-fit: contain;
		}
	}
`

export default LogoStyle
