import { forwardRef, useRef, useImperativeHandle } from 'react'
import Image from 'next/image'

//* Style
import ImageStyle from './style'

const CustomImage = forwardRef(({ src, alt, className, width, height }, ref) => {
	//! Ref
	const imgRef = useRef()

	useImperativeHandle(ref, () => imgRef.current, [])

	return (
		<ImageStyle
			ref={imgRef}
			style={{ width, height }}
			className={`image-cont ${className || ``}`}
		>
			<Image
				fill
				priority={true}
				draggable={false}
				alt={alt || `image`}
				src={src || `/images/default.jpeg`}
				unoptimized={src && src.includes(`.svg`)}
			/>
		</ImageStyle>
	)
})

CustomImage.displayName = `CustomImage`

export default CustomImage
