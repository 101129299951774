import { memo as Memo, useMemo, useRef, useEffect, useState, useCallback } from 'react'
import gsap from 'gsap'

//* HOCs
import withLanguageContext from 'context/consumerHOC/LanguageConsumer'
import withUIContext from 'context/consumerHOC/UIConsumer'

//* Helper
import { useActiveSection } from 'helpers'

//* Components
import CustomLink from 'components/common/CustomLink'
import Icon from 'components/common/Icon'
import LottieAnimation from 'components/common/LottieAnimation'

//* Lottie file
import leftArrow from '../../../lottieFiles/leftArrow.json'

//* Style
import ButtonStyle from './style'

const Button = Memo(({ btnType, text, className, onClick, url, customLinkProps, translate, target, type = 'button', winWidth, selectedLang, ...props }) => {
    const Component = useMemo(() => (url ? CustomLink : 'button'), [url])
    const customProps = useMemo(() => (url ? { ...customLinkProps, url, target } : { type }), [url])

    let btn

    //! Refs
    const isAnimate = useRef()
    const oldWinWidth = useRef(1920)
    const timeout = useRef()
    const rectRef = useRef()
    const animBtnContRef = useRef()
    const animBtnInnerContRef = useRef()
    const animBtnIconContRef = useRef()
    const animBtnIconRef = useRef()

    //! States
    const activeSection = useActiveSection(animBtnContRef, 95)
    const [arrowLottie, setArrowLottie] = useState(false)

    //! Animate
    const animate = useCallback(() => {
        gsap.to(animBtnContRef.current, { scale: 1, duration: 0.3 }).then(() => {
            gsap.to(animBtnIconRef.current, { rotate: 0, duration: 0.3 }).then(() => {
                const animBtnIconContSize = animBtnIconContRef.current && animBtnIconContRef.current.getBoundingClientRect().width
                if (animBtnIconContSize) {
                    let startAnimation = false

                    const anim = gsap.to(animBtnContRef.current, {
                        duration: 0.3,
                        width: 'auto',
                        onUpdate: () => {
                            const pr = anim.progress()

                            if (pr >= 0.5 && !startAnimation) {
                                gsap.set(animBtnInnerContRef.current, { delay: 0.1, opacity: 1 })
                                startAnimation = true
                            }

                            if (startAnimation) {
                                const animBtnContWidth = animBtnContRef.current.getBoundingClientRect().width

                                gsap.to(animBtnIconContRef.current, { left: animBtnContWidth - animBtnIconContSize - 10 })
                            }
                        },
                    })
                }
            })
        })
    }, [])

    //! Animation
    useEffect(() => {
        if (!btnType) {
            if (oldWinWidth.current !== winWidth && isAnimate.current) {
                oldWinWidth.current = winWidth

                gsap.set([animBtnContRef.current, animBtnIconRef.current, rectRef.current, animBtnInnerContRef.current, animBtnIconContRef.current], { clearProps: 'all' })

                timeout.current && clearTimeout(timeout.current)

                timeout.current = setTimeout(() => {
                    animate()
                }, 500)
            }

            if (activeSection && !isAnimate.current) {
                isAnimate.current = true
            }
        }
    }, [activeSection, winWidth, btnType, selectedLang])

    //! On Mouse Enter
    const handleMouseEnter = useCallback(() => {
        gsap.killTweensOf(animBtnIconRef.current)
        gsap.to(animBtnIconRef.current, { rotate: -50, duration: 0.15 }).then(() => {
            gsap.to(animBtnIconRef.current, { rotate: 50, duration: 0.3 }).then(() => {
                gsap.to(animBtnIconRef.current, { rotate: 0, duration: 0.15 })
            })
        })
    }, [])

    //! On Mouse Leave
    const handleMouseLeave = useCallback(() => {
        gsap.killTweensOf(animBtnIconRef.current)
        gsap.to(animBtnIconRef.current, { rotate: 0, duration: 0.15 })
    }, [])

    switch (btnType) {
        case 'with-bg-color':
            btn = (
                <ButtonStyle
                    onClick={onClick}
                    className={`${btnType} btn-wrap ${className || ''} p p3`}
                >
                    <div className={`fake-bg-color-for-anim`} />

                    <Component
                        ref={animBtnContRef}
                        {...customProps}
                    >
                        {props.children || translate(text)}{' '}
                    </Component>
                </ButtonStyle>
            )
            break

        case 'with-bg-transparent':
            btn = (
                <ButtonStyle
                    onClick={onClick}
                    className={`${btnType} btn-wrap ${className || ''} p p3`}
                >
                    <div className={`fake-bg-transparent-for-anim `} />

                    <Component
                        ref={animBtnContRef}
                        {...customProps}
                    >
                        {props.children || translate(text)}{' '}
                    </Component>
                </ButtonStyle>
            )
            break

        case 'with-lottie':
            btn = (
                <ButtonStyle
                    onClick={onClick}
                    onMouseEnter={() => setArrowLottie(true)}
                    onMouseLeave={() => setArrowLottie(false)}
                    className={`${btnType} btn-wrap ${className || ''} p p4`}
                >
                    <Component
                        ref={animBtnContRef}
                        {...customProps}
                    >
                        {winWidth >= 1280 ? (
                            <LottieAnimation
                                animData={leftArrow}
                                autoplay={false}
                                onCompletePause={!arrowLottie}
                                isPlay={arrowLottie}
                                loop={true}
                            />
                        ) : (
                            <LottieAnimation
                                animData={leftArrow}
                                autoplay={false}
                                onCompletePause={false}
                                isPlay={false}
                                loop={false}
                                isPaused={true}
                            />
                        )}

                        {translate(text)}
                    </Component>
                </ButtonStyle>
            )
            break

        default:
            btn = (
                <ButtonStyle
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={onClick}
                    className={`${btnType || ''} ${className || ''} p p3 notoSans-medium anim-btn`}
                >
                    <div className='anim-btn-inner-cont fake'>{props.children || translate(text)}</div>

                    <Component
                        ref={animBtnContRef}
                        className='anim-btn-cont'
                        {...customProps}
                    >
                        <div
                            ref={animBtnInnerContRef}
                            className='anim-btn-inner-cont'
                        >
                            {props.children || translate(text)}
                        </div>

                        <div
                            ref={animBtnIconContRef}
                            className='anim-btn-icon-cont'
                        >
                            <Icon
                                ref={animBtnIconRef}
                                name='arrow'
                                className={props.iconClassName || ''}
                            />
                        </div>
                    </Component>
                </ButtonStyle>
            )
    }

    return btn
})

export default withLanguageContext(withUIContext(Button, ['winWidth']), ['translate', 'selectedLang'])
