import { memo as Memo, useRef, useEffect, useState, useCallback } from 'react'
import { withRouter } from 'next/router'
import Link from 'next/link'

//* HOCs
import withUIContext from 'context/consumerHOC/UIConsumer'
import withLanguageContext from 'context/consumerHOC/LanguageConsumer'

//* Helper
import gsap from 'gsap'

//* Components
import Text from 'components/common/Text'
import Icon from 'components/common/Icon'

//* Style
import LanguageSwitcherStyle from './style'

const LanguageSwitcher = Memo(({ languages, className, isBurgerMenuOpen, ...props }) => {
	//! Refs
	const contRef = useRef()
	const bodyRef = useRef()
	const oldScrollVal = useRef(0)

	//! State
	const [opened, setOpened] = useState(false)

	//! Window Click Listener for close
	const handleWindowClick = useCallback(
		(e) => {
			if (!contRef.current.contains(e.target)) {
				setOpened(false)
			}
		},
		[opened]
	)

	//! Close the drop-down of the language in case of the scroll to the down
	const onScroll = useCallback((e) => {
		if (window.scrollY > oldScrollVal.current && window.scrollY > 0) {
			setOpened(false)
		}

		oldScrollVal.current = window.scrollY
	}, [])

	//! Toggle Collapse
	useEffect(() => {
		gsap.to(bodyRef.current, { height: opened ? 'auto' : 0, duration: 0.3 })
	}, [opened])

	//! Did Mount
	useEffect(() => {
		window.addEventListener('click', handleWindowClick)

		return () => {
			window.removeEventListener('click', handleWindowClick)
		}
	}, [])

	//! Scroll
	useEffect(() => {
		window.addEventListener('scroll', onScroll)

		return () => {
			window.removeEventListener('scroll', onScroll)
		}
	}, [onScroll])

	return (
		<LanguageSwitcherStyle className={`${className || ''} ${opened ? 'opened' : ''}`}>
			<div
				ref={contRef}
				className={`selected-lang-wrapper`}
				onClick={() => setOpened(!opened)}
			>
				<Text
					tag={`p`}
					ref={contRef}
					text={languages[props.router.locale].params.name}
					className={`p p5 notoSans-medium primary-color9`}
				/>

				<Icon name={`bottom-arrow ${opened ? 'rotate-icon' : ''}`} />
			</div>

			<div
				ref={bodyRef}
				className={`languages-cont`}
			>
				<div className={`body-container ${isBurgerMenuOpen ? `primary-color1-bg` : `body-color-bg`}`}>
					{Object.keys(languages)
						.filter((lang) => lang !== props.router.locale && lang !== 'de' && lang !== 'es' && lang !== 'fr')
						.map((lang) => {
							return (
								<Link
									key={lang}
									locale={lang}
									href={props.router.asPath}
									className={`lang-item align-center p p5 notoSans-medium primary-color9`}
								>
									{languages[lang].params.name}
								</Link>
							)
						})}
				</div>
			</div>
		</LanguageSwitcherStyle>
	)
})

export default withRouter(withLanguageContext(withUIContext(LanguageSwitcher, ['isBurgerMenuOpen']), ['languages']))
