import styled from 'styled-components'

const AgeGateRejectionStyle = styled.div`
	--ageGateRejectionTitle: var(--sp4x);
	--contentWidth: calc(var(--sp200x) + var(--sp86x));
	--contentWrapPadding: var(--sp31x) 0;
	--ageGateLogoTop: var(--sp2x);

	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	z-index: 100;
	background-color: var(--primaryColor1);

	.age-gate-logo {
		position: fixed;
		width: var(--logoWidth);
		top: var(--ageGateLogoTop);
		left: 50%;
		transform: translateX(-50%);
		z-index: 1000;

		.image-cont {
			--proportion: var(--logoHeight);
		}
	}

	.content-wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		padding: var(--contentWrapPadding);
		border-top: 1px solid var(--primaryColor9);
		border-bottom: 1px solid var(--primaryColor9);
		width: var(--contentWidth);

		.title {
			margin-bottom: var(--ageGateRejectionTitle);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--ageGateRejectionTitle: var(--sp3x);
		--contentWidth: calc(var(--sp200x) + var(--sp14x));
		--contentWrapPadding: var(--sp24x) 0;
		--ageGateLogoTop: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--ageGateRejectionTitle: var(--sp3x);
		--contentWidth: var(--sp167x);
		--contentWrapPadding: var(--sp19x) 0;
		--ageGateLogoTop: var(--sp1x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--ageGateRejectionTitle: var(--sp2x);
		--contentWidth: var(--sp164x);
		--contentWrapPadding: var(--sp16x) 0;
		--ageGateLogoTop: var(--sp1x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--ageGateRejectionTitle: var(--sp3x);
		--contentWidth: var(--sp146x);
		--contentWrapPadding: var(--sp18x) 0;
		--ageGateLogoTop: var(--sp1x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--ageGateRejectionTitle: var(--sp2x);
		--contentWidth: var(--sp116x);
		--contentWrapPadding: var(--sp20x) 0;
		--ageGateLogoTop: var(--sp1-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--ageGateRejectionTitle: var(--sp2x);
		--contentWidth: var(--sp84x);
		--contentWrapPadding: var(--sp29x) 0;
		--ageGateLogoTop: var(--sp1-5x);

		.content-wrap {
			.title {
				max-width: var(--sp70x);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--ageGateRejectionTitle: var(--sp2x);
		--contentWidth: var(--sp45x);
		--contentWrapPadding: var(--sp26x) 0;
		--ageGateLogoTop: var(--sp1-5x);
	}
`

export default AgeGateRejectionStyle
